<template>
  <div class="security">
    <div class="top d-flex">
      <div class="top-left col-3">
        <img src="~assets/images/icon/seucrity.png" alt="" />
      </div>
      <div class="top-right col-9">
        <section>Safety rating: excellent</section>
        <p>Please keep it up</p>
      </div>
    </div>
    <div class="list">
      <div class="info d-flex">
        <section class="col-2">
          <img src="~assets/images/icon/true.png" alt="" />
          <p>Bound</p>
        </section>
        <section class="col-3">phone number :</section>
        <section class="col-5">
          After binding the phone, you can enjoy our rich page services, such as
          phone password recovery and so on.
        </section>
        <section class="col-2 button" @click="show(0)">Modify</section>
      </div>
      <div class="info d-flex">
        <section class="col-2">
          <img src="~assets/images/icon/true.png" alt="" />
          <p>Set</p>
        </section>
        <section class="col-3">password :</section>
        <section class="col-5">
          A secure password can make your account more secure. It is recommended
          that you change your password regularly and set a password that
          contains Numbers and letters and is longer than 6 digits.
        </section>
        <section class="col-2 button" @click="show(1)">Modify</section>
      </div>
      <div class="info d-flex">
        <section class="col-2">
          <img src="~assets/images/icon/true.png" alt="" />
          <p>Bound</p>
        </section>
        <section class="col-3">email:</section>
        <section class="col-5">
          After binding the mailbox, you can enjoy our rich page services, such
          as email password retrieval.
        </section>
        <section class="col-2 button" @click="show(2)">Modify</section>
      </div>
    </div>
    <div class="show d-lg-block" v-if="detail">
      <div class="show-top">
        <div class="top-title">Change</div>
        <div class="top-close" @click="close">close</div>
      </div>
      <div class="show-code">
        <section>Current mailbox number</section>
        <div class="single">
          <div class="single-flex d-lg-flex d-xl-flex">
            <input
              class="col-lg-7 col-xl-7 col-sm-12 col-xs-12"
              type="text"
              placeholder="Please enter the email verification code"
              v-model="ruleForm.code"
            />
            <div
              class="code-button col-lg-4 col-xl-4"
              :class="Traem ? 'code-button' : 'code-button1'"
              @click="gain"
            >
              {{ Ytxfont }}
            </div>
          </div>
        </div>
        <div class="submit" @click="path">
          <span>Submit the certification</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { send, check } from "network/email";
export default {
  data() {
    return {
      detail: false,
      type: 0,
      ruleForm: {
        userName: "",
        code: "",
      },
      Traem: true,
      Ytxfont: "Get verification code",
      check: "",
    };
  },
  methods: {
    show(type) {
      this.detail = true;
      this.type = type;
    },
    close() {
      this.detail = false;
    },
    path() {
      let event = "";
      if (this.type == 0) {
        event = "changemobile";
      } else if (this.type == 1) {
        event = "changepwd";
      } else if (this.type == 2) {
        event = "changeemail";
      }
      check(this.$store.state.userinfo.email, event, this.ruleForm.code).then(
        (res) => {
          if (res.data.code == 1) {
            this.$router.push("/alter/" + this.type);
          } else {
            this.$notify({
              message: res.data.msg,
              type: "error",
              duration: 3000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
          }
        }
      );
      this.ruleForm.code = "";
      this.detail = false;
    },
    gain() {
      if (this.Traem) {
        this.Traem = false;
        let langtime = 60;
        let than = this;
        let a = setInterval(function () {
          if (langtime == 0) {
            than.Traem = true;
            than.Ytxfont = "Get verification code";
            clearInterval(a);
          } else {
            langtime--;
            than.Ytxfont = `  Resend in ${langtime} seconds `;
          }
        }, 1000);
      } else {
        return false;
      }
      let event = "";
      if (this.type == 0) {
        event = "changemobile";
      } else if (this.type == 1) {
        event = "changepwd";
      } else if (this.type == 2) {
        event = "changeemail";
      }

      send(this.$store.state.userinfo.email, event).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.security {
  width: 100%;
}
p {
  margin: 0;
}
.top {
  margin-top: 0.5rem;
  border: 1px solid #ededed;
  padding: 0.25rem;
  align-items: center;
}
.top div {
  margin: 0;
  padding: 0;
}
.top-left img {
  width: 2.5rem;
  height: 2.5rem;
}
.top-right section {
  color: #333333;
  font-size: 0.55rem;
}
.top-right p {
  color: #4c4c4c;
  font-size: 0.35rem;
}
.list {
  margin-top: 0.5rem;
  padding-bottom: 3rem;
}
.info {
  padding: 0.5rem 0;
  border-bottom: 1px solid #ededed;
}
.info section {
  padding: 0;
  font-size: 0.35rem;
  color: #4c4c4c;
  text-align: center;
}
.button {
  color: #2f74eb !important;
  cursor: pointer;
}

/** */
.show {
  position: fixed;
  background: #ff7f00;
  top: 30%;
  width: 40%;
  border-radius: 0.1rem;
  z-index: 99;
}
.show-top {
  font-size: 0.3rem;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.25rem;
  color: #fff;
}
.top-close {
  cursor: pointer;
}

.detail {
  color: #ff7f00;
  text-decoration: underline;
  margin: 1rem 0 0.25rem 0;
  cursor: pointer;
}
.show-code {
  background: #fff;
}

.code-button {
  font-size: 0.4rem;
  color: #ff7f00;
  padding: 0.3rem 0.5rem;
  border: 1px solid #ff7f00;
  border-radius: 0.2rem;
  text-align: center;
  cursor: pointer;
}
.code-button1 {
  font-size: 0.4rem;
  color: #fff;
  padding: 0.3rem 0.5rem;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 0.2rem;
  text-align: center;
  cursor: pointer;
}
.show-code {
  margin: 0 0.25rem 0.25rem 0.25rem;
  padding: 2%;
}
.show-code section {
  font-size: 0.35rem;
  padding-top: 1rem;
  color: #4c4c4c;
}

.single {
  margin-top: 0.75rem;
}

.form {
  margin: 0.25rem auto;
}
.single-flex {
  justify-content: space-between;
  margin-top: 0.25rem;
}
.single input {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
}
.submit {
  text-align: center;
  margin: 0.5rem 0 2rem 0;
}
.submit span {
  font-size: 0.35rem;
  color: #fff;
  padding: 0.4rem 2rem;
  background: #ff7f00;
  border-radius: 0.25rem;
  cursor: pointer;
}

@media screen and (max-width: 1800px) {
  .show {
    width: 50%;
  }
}

@media screen and (max-width: 1300px) {
  /*bootstrap 小屏幕*/
  .show {
    width: 60%;
  }
}

@media screen and (max-width: 990px) {
  .show {
    width: 80%;
  }
  .code-button {
    font-size: 0.4rem;
    color: #ff7f00;
    padding: 0.3rem 0.5rem;
    border: 1px solid #ff7f00;
    border-radius: 0.2rem;
    text-align: center;
    cursor: pointer;
    margin-top: 0.5rem;
  }
}
</style>